import React, { useEffect, useRef, useState } from "react";
import Cx from "./page.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaRegEye } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { GrFormEdit } from "react-icons/gr";
import { TbActivityHeartbeat, TbVideo } from "react-icons/tb";
import DefaultFooter from "examples/Footers/DefaultFooter";
import MKBox from "components/MKBox";
import footerRoutes from "footer.routes";
import Header from "layouts/hearder/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchGlobalEvents } from "../../../redux/slice/globalSlice";
import moment from "moment";
const DashboardBusiness = () => {
  const fileInputRef = useRef(null);
  const userId = localStorage.getItem("userId");
  const [dashboardData, setDashboardData] = useState({});
  const dispatch = useDispatch();
  const { upcomingEvents } = useSelector((state) => state.global);
  const email = localStorage.getItem("email");
  const [loeData, setLoeData] = useState();
  const [loeStatus, setLoeStatus] = useState();

  const fetchLoeEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}loeapp/?user_id=${userId}`
      );

      if (response) {
        setLoeData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    if (email) {
      dispatch(fetchGlobalEvents(email));
    }
  }, [dispatch, email]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/${userId}/`);
      setDashboardData(response.data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const fetchDataLoeStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}document/?user_id=${userId}`
      );
      const taxLoeData =
        response?.data && response?.data?.results?.filter((event) => event.role === "business");

      setLoeStatus(taxLoeData);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchLoeEvents();
    fetchDataLoeStatus();
  }, [userId]);

  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}calendly/events/`,
          { email: localStorage.getItem("email") },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setEvents(response.data);
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    };

    fetchEvents();
  }, []);

  const handleFileChange = async (event, fileType) => {
    const file = event.target.files[0];
    if (file) {
      await onFileUploadHandler({ [fileType]: file });
    }
  };

  const handleViewPdf = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  const onFileUploadHandler = async (filesToUpload) => {
    try {
      if (!userId) {
        console.error("User ID not found");
        return;
      }

      const formData = new FormData();
      Object.keys(filesToUpload).forEach((key) => {
        if (filesToUpload[key]) {
          formData.append(key, filesToUpload[key]);
        }
      });

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}user/${userId}/`,
        formData,
        config
      );

      if (response.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const triggerFileInput = (fileType) => {
    fileInputRef.current.setAttribute("data-file-type", fileType);
    fileInputRef.current.click();
  };

  const formatTime = (isoString) => {
    try {
      return moment(isoString).format("MMMM D, YYYY h:mm A");
    } catch (error) {
      console.error("Invalid date format", error);
      return "Invalid date";
    }
  };

  const businessLoeData = loeData && loeData?.filter((event) => event?.role === "business");

  const setLocalUserType = () => {
    localStorage.setItem("UserType", "is_business");
  };

  const businessSteps = {
    "Discovery Call": "discovery_call",
    "AML Check Complete": "aml_check_complete",
    "Business Finance Review": "business_finance_review",
    "Documents Provided": "documents_provided",
    "Application Submitted": "application_submitted",
    "Options Presented": "options_presented",
    "Business Finance Confirmed": "business_finance_confirmed",
    "T's & C's Signed": "ts_and_cs_signed",
    "Finance Delivered": "finance_delivered",
  };

  const getStatusLabel = (status) => {
    return (
      Object.keys(businessSteps).find((key) => businessSteps[key] === status) || "Status not found"
    );
  };
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={Cx.accounts3}></div>
            </div>
            <div className="col-lg-8 m-auto">
              <div className={Cx.whatarelooking}>
                <div className={Cx.mainheadingtop}>
                  <h1>Dashboard</h1>
                </div>
                <div className={Cx.dashboardForm}>
                  <div className={Cx.accounts}>
                    <div className={Cx.icondoc}>
                      <CgFileDocument />
                    </div>
                    <h2>Q.1 VAT Return</h2>
                    <div className={Cx.iconsponts}>
                      {dashboardData?.Vatq1 && (
                        <span
                          onClick={() => handleViewPdf(dashboardData.Vatq1)}
                          className={Cx.iconcircel}
                        >
                          <FaRegEye />
                        </span>
                      )}
                      <span
                        onClick={() => triggerFileInput("Vatq1")}
                        style={{ position: "relative" }}
                      >
                        <GrFormEdit className={Cx.editcircelbtn} />
                      </span>
                    </div>
                  </div>
                  <div className={Cx.accounts}>
                    <div className={Cx.icondoc}>
                      <CgFileDocument />
                    </div>
                    <div>
                      <h2>Q.2 VAT Return</h2>
                    </div>
                    <div className={Cx.iconsponts}>
                      {dashboardData?.Vatq2 && (
                        <span
                          onClick={() => handleViewPdf(dashboardData.Vatq2)}
                          className={Cx.iconcircel}
                        >
                          <FaRegEye />
                        </span>
                      )}
                      <span
                        onClick={() => triggerFileInput("Vatq2")}
                        style={{ position: "relative" }}
                      >
                        <GrFormEdit className={Cx.editcircelbtn} />
                      </span>
                    </div>
                  </div>

                  <div className={Cx.accounts}>
                    <div className={Cx.icondoc}>
                      <CgFileDocument />
                    </div>
                    <h2>Q.3 VAT Return</h2>
                    <div className={Cx.iconsponts}>
                      {dashboardData?.Vatq3 && (
                        <span
                          onClick={() => handleViewPdf(dashboardData.Vatq3)}
                          className={Cx.iconcircel}
                        >
                          <FaRegEye />
                        </span>
                      )}
                      <span
                        onClick={() => triggerFileInput("Vatq3")}
                        style={{ position: "relative" }}
                      >
                        <GrFormEdit className={Cx.editcircelbtn} />
                      </span>
                    </div>
                  </div>
                  <div className={Cx.accounts}>
                    <div className={Cx.icondoc}>
                      <CgFileDocument />
                    </div>
                    <h2>Q.4 VAT Return</h2>
                    <div className={Cx.iconsponts}>
                      {dashboardData?.Vatq4 && (
                        <span
                          onClick={() => handleViewPdf(dashboardData.Vatq4)}
                          className={Cx.iconcircel}
                        >
                          <FaRegEye />
                        </span>
                      )}
                      <span
                        onClick={() => triggerFileInput("Vatq4")}
                        style={{ position: "relative" }}
                      >
                        <GrFormEdit className={Cx.editcircelbtn} />
                      </span>
                    </div>
                  </div>
                  <div className={Cx.accounts}>
                    <div className={Cx.icondoc}>
                      <CgFileDocument />
                    </div>
                    <h2>Most recent filed accounts</h2>
                    <div className={Cx.iconsponts}>
                      {dashboardData?.Busi_accounts && (
                        <span
                          onClick={() => handleViewPdf(dashboardData.Busi_accounts)}
                          className={Cx.iconcircel}
                        >
                          <FaRegEye />
                        </span>
                      )}
                      <span
                        onClick={() => triggerFileInput("Busi_accounts")}
                        style={{ position: "relative" }}
                      >
                        <GrFormEdit className={Cx.editcircelbtn} />
                      </span>
                    </div>
                  </div>

                  <div className={Cx.accounts}>
                    <div className={Cx.icondoc}>
                      <CgFileDocument />
                    </div>
                    <h2>Last 6 months of business bank statements</h2>
                    <div className={Cx.iconsponts}>
                      {dashboardData?.businessbankstatement && (
                        <span
                          onClick={() => handleViewPdf(dashboardData.businessbankstatement)}
                          className={Cx.iconcircel}
                        >
                          <FaRegEye />
                        </span>
                      )}
                      <span
                        onClick={() => triggerFileInput("businessbankstatement")}
                        style={{ position: "relative" }}
                      >
                        <GrFormEdit className={Cx.editcircelbtn} />
                      </span>
                    </div>
                  </div>
                  {businessLoeData && businessLoeData?.length > 0 && (
                    <div className="mt-3">
                      {businessLoeData &&
                        businessLoeData?.map((event, index) => {
                          return (
                            <div className={Cx.accounts3} key={index}>
                              <div className={Cx.icondoc} style={{}}>
                                <CgFileDocument />
                              </div>
                              <div className={Cx.statustext}>
                                <h2>Letter of Engagement</h2>
                              </div>
                              <div className={Cx.iconsponts} style={{ width: "0px" }}>
                                <Link to={event?.loe_link} target="_blank">
                                  <h5>
                                    {loeStatus &&
                                      loeStatus?.map((item, index) => (
                                        <span key={index}>
                                          {item?.signstatus === "signed" ? "Signed" : "Sign here"}
                                        </span>
                                      ))}
                                  </h5>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) =>
                      handleFileChange(e, fileInputRef.current.getAttribute("data-file-type"))
                    }
                    style={{ display: "none" }}
                  />

                  {events?.upcoming?.length > 0 && (
                    <div className="mt-5">
                      <div className={Cx.accounts4}>
                        <div className={Cx.icondoc}>
                          <TbVideo />
                        </div>
                        <div className={Cx.statustext}>
                          <h5>
                            <b>Next Meeting Time</b>
                          </h5>
                          <p>Start Time: {formatTime(upcomingEvents[0]?.start_time)}</p>
                          <Link to={upcomingEvents[0]?.location?.join_url} target="_blank">
                            Join Meeting Link
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mt-5">
                    <div className={Cx.accounts4}>
                      <div className={Cx.icondoc}>
                        <TbActivityHeartbeat />
                      </div>
                      <div className={Cx.statustext}>
                        <Link to={"/application-status"} onClick={setLocalUserType}>
                          <h5>
                            <b>Status</b>
                          </h5>
                          <p>{getStatusLabel(dashboardData?.business_status)}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default DashboardBusiness;
