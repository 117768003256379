import React, { useState } from "react";
import Cx from "./page.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { showErrorToast, showSuccessToast } from "../../../utils/Toster";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/slice/authSlice";
import axios from "axios";

const SignInOld = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onsetWelcomeQuesResponceHandler = async (userId) => {
    try {
      const resData = await axios.get(`${process.env.REACT_APP_BASE_URL}user/${userId}`);
      console.log(resData, "resData?.data?.annual_turnover");
      return resData?.data;
    } catch (error) {
      console.log("Error fetching question response:", error);
    }
  };

  const onSubmit = async (data) => {
    try {
      const actionResult = await dispatch(loginUser(data));
      if (loginUser.fulfilled.match(actionResult)) {
        localStorage.setItem("userId", actionResult?.payload?.data?.id);
        localStorage.setItem("email", actionResult?.payload?.data?.email);

        const userId = actionResult?.payload?.data?.id;
        const ApiData = await onsetWelcomeQuesResponceHandler(userId);
        if (ApiData) {
          if (
            (actionResult?.payload?.data?.is_taxrelief &&
              actionResult?.payload?.data?.is_business) ||
            (!actionResult?.payload?.data?.is_taxrelief &&
              !actionResult?.payload?.data?.is_business)
          ) {
            navigate("/lookingfor");
          } else if (actionResult?.payload?.data?.is_taxrelief) {
            localStorage.setItem("UserType", "is_taxrelief");
            navigate("/dashboard");
          } else if (actionResult?.payload?.data?.is_business && ApiData?.annual_turnover == 0.0) {
            localStorage.setItem("UserType", "is_business");
            navigate("/welcome-analysis");
          } else if (actionResult?.payload?.data?.is_business && ApiData?.annual_turnover !== 0.0) {
            localStorage.setItem("UserType", "is_business");
            navigate("/dashboard-business");
          } else {
            navigate("/lookingfor");
          }
          showSuccessToast("Sign In Successfully");
        } else {
          showErrorToast("Sign In failed");
        }
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-5 mb-5">
            <div className={Cx.accounts}>
              <Link to="/home" className={Cx.backbtn}>
                <IoArrowBackOutline />
              </Link>
              <div className={Cx.mainheading}>
                <h2>Login</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <form onSubmit={handleSubmit(onSubmit)} className={Cx.signupForm}>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faEnvelope} className={Cx.icon} />
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^\S+@\S+\.\S+$/,
                        message: "Enter a valid email address",
                      },
                    })}
                  />
                </div>
                {errors.email && <p className={Cx.errorMessage}>{errors.email.message}</p>}
              </div>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faLock} className={Cx.icon} />
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      maxLength: {
                        value: 16,
                        message: "Password must not exceed 16 characters",
                      },
                    })}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className={Cx.toggleIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
                {errors.password && <p className={Cx.errorMessage}>{errors.password.message}</p>}
              </div>
              <div className={`${Cx.forgetline} ${Cx.mainheading}`}>
                <Link to="/forget-password" className={Cx.linkbtn}>
                  Forgot Password?
                </Link>
              </div>
              <div className={Cx.mainheading}>
                <button type="submit" className={Cx.submitButton2}>
                  Sign in
                </button>
              </div>

              <div className={Cx.mainheading}>
                <p>
                  Don&apos;t have an account?
                  <br />
                  <span>
                    <Link to={"/register"} className={Cx.linkbtn}>
                      Sign Up!
                    </Link>
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignInOld;
