import React, { useEffect } from "react";
import Cx from "./page.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTimes, faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useForm, useFieldArray } from "react-hook-form";
import axios from "axios";

const ProjectDocumentUpload = () => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      documents: [{ document_title: "", document_upload: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "documents",
  });

  const navigate = useNavigate();
  const id = localStorage.getItem("userId");

  const fetchSupDoc = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/${id}/`);
    const documents = response?.data?.supportingdocuments;
    const formattedDocuments =
      documents &&
      documents.map((doc) => ({
        document_title: doc?.title,
        document_upload: doc?.s3Link,
      }));
    setValue(
      "documents",
      formattedDocuments?.length > 0
        ? formattedDocuments
        : [{ document_title: "", document_upload: "" }]
    );
  };

  useEffect(() => {
    fetchSupDoc();
  }, []);

  const uploadAudioFile = async (audioFile) => {
    const formData = new FormData();
    formData.append("audio_file", audioFile);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}audio/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading audio file:", error);
      throw error;
    }
  };

  const onSubmit = async (formData) => {
    try {
      const dataToSend = [];

      for (const doc of formData.documents) {
        let documentUpload = null;

        if (doc?.document_upload?.[0]) {
          if (doc.document_upload[0] instanceof File) {
            const uploadResponse = await uploadAudioFile(doc.document_upload[0]);
            documentUpload = uploadResponse?.audio_file;
          }
        }

        if (documentUpload) {
          dataToSend.push({
            title: doc.document_title || "",
            s3Link: documentUpload || "",
          });
        } else if (doc.document_upload) {
          dataToSend.push({
            title: doc.document_title || "",
            s3Link: doc.document_upload || "",
          });
        } else {
          console.warn("No document upload found for:", doc);
        }
      }

      if (dataToSend.length > 0) {
        const response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}user/${id}/`,
          { supportingdocuments: dataToSend },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        fetchSupDoc();
        console.log(response);
      } else {
        console.warn("dataToSend is empty, not sending request.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const viewPDF = (s3Link) => {
    window.open(s3Link, "_blank");
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 m-auto">
            <div className={Cx.supportingdocuments}>
              <div className={Cx.supportingdocumentstop}>
                <span className={Cx.backbtn} onClick={() => navigate(-1)}>
                  <IoArrowBackOutline />
                </span>
                <div className={Cx.introText}>
                  <h2>Supporting Documents</h2>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className={Cx.projectdocument}>
                {fields.map((field, index) => (
                  <div key={field.id} className={Cx.formGroup}>
                    <input
                      type="text"
                      placeholder="Document Title"
                      {...register(`documents.${index}.document_title`, {
                        required: "Document title is required",
                      })}
                      className={Cx.inputTitle}
                    />
                    <div className={Cx.inputGroup}>
                      <div className={Cx.iconarea}>
                        <label htmlFor={`file-upload-${index}`} className={Cx.uploadIcon}>
                          <FontAwesomeIcon icon={faUpload} />
                        </label>
                        <span className={Cx.cancelIcon} onClick={() => remove(index)}>
                          <FontAwesomeIcon icon={faTimes} />
                        </span>
                        {field.document_upload && (
                          <span
                            className={Cx.eyeIcon}
                            onClick={() => viewPDF(field.document_upload)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </span>
                        )}
                      </div>
                      <input
                        type="file"
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                        {...register(`documents.${index}.document_upload`, {
                          required: !field.document_upload ? "Document is required" : false, // Conditional required
                        })}
                        id={`file-upload-${index}`}
                        style={{ display: "none" }}
                      />
                    </div>
                    {errors.documents?.[index]?.document_upload && (
                      <p className={Cx.errorMessage}>
                        {errors.documents[index].document_upload.message}
                      </p>
                    )}
                  </div>
                ))}

                <div className={Cx.btnareaAddmore}>
                  <button
                    type="button"
                    className={Cx.submitButton2}
                    onClick={() => append({ document_title: "", document_upload: "" })}
                  >
                    Add More Document
                  </button>
                </div>
                <div className={Cx.btnareaAddmore}>
                  <button type="submit" className={Cx.submitButton2}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDocumentUpload;
