// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import Cx from "../../style/custom.module.scss";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import bgFront from "assets/imagesAG/AGfinance1.png";
import bgBack from "assets/imagesAG/AGfinance5.png";

function Yourjourney() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3}>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              mx: "auto",
              position: "sticky",
              top: "90px",
              alignSelf: "flex-start",
            }}
          >
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Feel the
                    <br />
                    AG Finance
                  </>
                }
                description="All the MUI components that you need in a development have been re-designed with the new look."
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "start with header",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Grid container spacing={3}>
              <Grid item className={Cx.main_heading}>
                <h2>Your journey, our expertise, one destination - AG Finance Hub</h2>
                <p>
                  We are thrilled to announce that Levy Gera is now part of the AG Finance and
                  Technology Group—the world&apos;s first tech-led corporate tax and finance
                  solutions company serving UK businesses. The AG Finance Hub is the only app that
                  allows UK businesses to unlock corporate tax relief from start to finish, while
                  also providing UK businesses free access to the UK&apos;s largest network of over
                  140 lenders for bespoke business financing solutions ranging from £10,000 to
                  £20,000,000.
                </p>
                <Grid className={Cx.main_heading_text}>
                  <p>
                    No matter where you are on your business journey, whether you&apos;re just
                    starting out or managing a multimillion-pound empire, we&apos;re here to help.
                    Whether you need access to unsecured financing of up to £500k through a special
                    terms lender for UK tenants or homeowners, secured business loans, asset-backed
                    finance, bridging loans, invoice financing, spot factoring, working capital
                    finance, small business loans (including those for sole traders), property
                    finance (including commercial mortgages), merchant credit advances, or revolving
                    credit facilities—we&apos;ve got you covered.
                  </p>
                </Grid>
                <div className={Cx.main_heading_text}>
                  <p>
                    We also offer a wide range of chartered tax and accounting services. Our
                    award-winning expertise includes R&D Tax Credits (including HMRC enquiries),
                    Capital Allowances, Land Remediation Relief, EIS/SEIS share schemes, and
                    Chartered Tax services. Our comprehensive team consists of chartered tax and
                    accounting specialists, business finance experts, technical industry
                    specialists, fintech engineers, data scientists, machine learning specialists,
                    and legal experts where required.
                  </p>
                </div>
                <Grid className={Cx.main_heading_text}>
                  <p>
                    Our mission is to pioneer the way UK businesses unlock tax relief and access
                    business finance. We are developing proprietary AI technology designed to
                    automate, de-risk, and simplify corporate tax and financing processes in the UK.
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Yourjourney;
