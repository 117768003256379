import React, { useEffect, useState } from "react";
import Cx from "./page.module.scss";
import { useNavigate } from "react-router-dom";
import MKBox from "components/MKBox";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import Header from "layouts/hearder/Header";
import { registerUserStatus } from "../../../redux/slice/authSlice";
import { showErrorToast } from "../../../utils/Toster";
import { useDispatch } from "react-redux";
import axios from "axios";

const LookingFor = () => {
  const [activeButton, setActiveButton] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userId");
  const [discoveryQuesData, setDiscoveryQuesData] = useState();
  const [welcomeQuesData, setWelcomeQuesData] = useState();

  const onQuestionResponceHandler = async () => {
    try {
      const resData = await axios.get(
        `${process.env.REACT_APP_BASE_URL}answers/?user_id=${userId}`
      );
      setDiscoveryQuesData(resData?.data?.data);
    } catch (error) {
      console.log("Error fetching question response:", error);
    }
  };

  const onsetWelcomeQuesResponceHandler = async () => {
    try {
      const resData = await axios.get(`${process.env.REACT_APP_BASE_URL}user/${userId}`);
      setWelcomeQuesData(resData?.data?.annual_turnover);
    } catch (error) {
      console.log("Error fetching question response:", error);
    }
  };

  useEffect(() => {
    onQuestionResponceHandler();
    onsetWelcomeQuesResponceHandler();
  }, []);

  const handleButtonClick = async (buttonType) => {
    setActiveButton(buttonType);
    const id = localStorage.getItem("userId");
    if (buttonType === "Tax Relief") {
      const dataAll = {
        userId: id,
        is_taxrelief: true,
      };
      try {
        const actionResult = await dispatch(registerUserStatus(dataAll));
        if (registerUserStatus.fulfilled.match(actionResult)) {
          console.log("actionResult?.payload?.is_taxrelief", actionResult?.payload?.is_taxrelief);
          if (actionResult?.payload?.is_taxrelief) {
            if (discoveryQuesData?.length > 0) {
              navigate("/dashboard");
              localStorage.setItem("UserType", "is_taxrelief");
            } else {
              navigate("/discovery-analysis-add");
              localStorage.setItem("UserType", "is_taxrelief");
            }
          }
        } else {
          showErrorToast("status update failed");
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    } else {
      const dataAll = {
        userId: id,
        is_business: true,
      };
      try {
        const actionResult = await dispatch(registerUserStatus(dataAll));
        if (registerUserStatus?.fulfilled?.match(actionResult)) {
          if (actionResult?.payload?.is_business) {
            if (welcomeQuesData == 0.0) {
              navigate("/welcome-analysis");
              localStorage.setItem("UserType", "is_business");
            } else {
              navigate("/dashboard-business");
              localStorage.setItem("UserType", "is_business");
            }
          }
        } else {
          console.error("status update failed:", actionResult.payload);
          showErrorToast("status update failed");
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 m-auto ">
              <div className={Cx.whatarelooking}>
                <div className={Cx.textcardarea}>
                  <p>
                    Our team of expert business finance specialist and chartered finance
                    professionals do all the heavy lifting, so you can do all the decisioning.
                  </p>
                </div>
                <div className={Cx.textcardarea}>
                  <p>
                    Book a complimentary discover call with us today and explore tax relief and
                    business finance opportunities that can will give your business an edge.
                  </p>
                </div>
                <div className={Cx.mainheadinglookingfor}>
                  <h5>What are you looking for?</h5>
                </div>
                <form className={Cx.LookingforForm}>
                  <div className="">
                    <button
                      type="button"
                      className={`${Cx.taxbtn} ${activeButton === "Tax Relief" ? Cx.active : ""}`}
                      onClick={() => handleButtonClick("Tax Relief")}
                    >
                      Tax Relief
                    </button>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className={`${Cx.taxbtn} ${
                        activeButton === "Business Finance" ? Cx.active : ""
                      }`}
                      onClick={() => handleButtonClick(" Business Finance")}
                    >
                      Business Finance
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default LookingFor;
