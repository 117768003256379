// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import BuiltByDevelopers from "pages/components/BuiltByDevelopers";
// Routes
import routes from "routes";
// Images
import videoSource from "assets/imagesAG/AGFinancepromo.mp4";
/* import Counters from "pages/sections/Counters";
import Testimonials from "pages/sections/Testimonials";
import Information from "pages/sections/Information";
import MKTypography from "components/MKTypography";
import DesignBlocks from "pages/sections/DesignBlocks"; 
 import Grid from "@mui/material/Grid";
import Cx from "../../style/custom.module.scss";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard"; 
import bgImage from "assets/images/bg-presentation.jpg";
import Pages from "pages/sections/Pages"; 
import Download from "pages/sections/Download";
import MKSocialButton from "components/MKSocialButton"; */
/* footer */
import footerRoutes from "footer.routes";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Yourjourney from "pages/sections/Yourjourney";

function Home() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="90vh"
        width="100%"
        sx={{
          position: "relative", // Allows for absolute positioning of the video
          display: "grid",
          placeItems: "center",
          overflow: "hidden", // Ensures content stays within the box
        }}
      >
        {/* Background video */}
        <video
          autoPlay
          muted
          loop
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures the video covers the entire container
            transform: "translate(-50%, -50%)", // Centers the video
            zIndex: -1, // Keeps the video behind the content
          }}
        >
          <source src={videoSource} type="video/mp4" />{" "}
          {/* Replace videoSource with your actual video URL */}
          Your browser does not support the video tag.
        </video>

        {/* Centered content */}
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/*  <Information /> */}
        <Yourjourney />
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        {/*  <Counters /> */}
        {/*   <DesignBlocks /> */}
        {/* <Pages /> */}
        {/*  <Container sx={{ mt: 10 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plugins"
                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Components"
                description="Material Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container> */}

        {/*      <Container sx={{ mt: 10 }}>
          <Grid container spacing={3}>
            <Grid item md={12} lg={12}>
              <div className={Cx.mainheading}>
                <h2>AG Finance Hu</h2>
              </div>
            </Grid>
            <Grid item md={12} lg={6}>
              <div className={Cx.landingcard}>
                <h3>AG Finance</h3>
                <p>
                  No matter where you are on your business journey, whether you&apos;re just
                  starting out or managing a multimillion-pound empire, we&apos;re here to help.
                  Whether you need access to unsecured financing of up to £500k through a special
                  terms lender for UK tenants or homeowners, secured business loans, asset-backed
                  finance, bridging loans, invoice financing, spot factoring, working capital
                  finance, small business loans (including those for sole traders), property finance
                  (including commercial mortgages), merchant credit advances, or revolving credit
                  facilities—we&apos;ve got you covered.
                </p>
              </div>
            </Grid>
            <Grid item md={12} lg={6}>
              <div className={Cx.landingcard}>
                <h3>AG Finance</h3>
                <p>
                  We also offer a wide range of chartered tax and accounting services. Our
                  award-winning expertise includes R&D Tax Credits (including HMRC enquiries),
                  Capital Allowances, Land Remediation Relief, EIS/SEIS share schemes, and Chartered
                  Tax services. Our comprehensive team consists of chartered tax and accounting
                  specialists, business finance experts, technical industry specialists, fintech
                  engineers, data scientists, machine learning specialists, and legal experts where
                  required
                </p>
              </div>
            </Grid>
          </Grid>
        </Container> */}
        {/*    <Testimonials /> */}
        {/*  <Download /> */}
        {/*  <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We deliver the best web products
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23mui5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-kit-react"
                  target="_blank"
                  color="twitter"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Tweet
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox> */}
      </Card>
      {
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      }
    </>
  );
}

export default Home;
