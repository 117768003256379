import React from "react";
import { Link } from "react-router-dom";
import Cx from "./page.module.scss";
import { IoArrowBackOutline } from "react-icons/io5";

const QuestionInstructionScreen = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mt-5 mb-2">
            <div className={Cx.header}>
              <Link to="/" className={Cx.backbtn}>
                <IoArrowBackOutline />
              </Link>
            </div>
          </div>
          <div className="col-lg-10 m-auto ">
            <h2 className={Cx.title}>Instructions</h2>
            <div className={`${Cx.content} mb-5`}>
              <p className={Cx.paragraph}>
                We are looking for projects that you undertook throughout the year in which you had
                to overcome scientific or technological uncertainty. Uncertainties mean challenges
                of a scientific or technological nature that were not straightforward to overcome by
                a competent professional in the field. There are many types of scientific or
                technological fields an uncertainty can belong to, including niche multidisciplinary
                areas and a wide range of applied sciences such as engineering, design engineering,
                and so forth. If the uncertainties were of a mathematical nature, these can also
                work.
              </p>

              <p className={Cx.paragraph}>
                What we will do is for each project, go over the objectives and background as to how
                the project came about, identify the uncertainties within that project, and then the
                solutions, including what you did in-between to get to the solution and all the
                pinch and pain points you faced. Typically, we aim for 3 uncertainties per project
                and 3 solutions. If there are fewer, that fine, and if you were unable to find a
                solution, that is fine; the key thing is that you tried to achieve one. Once we have
                discussed the uncertainties and solutions in one project, we will go on to the next.
              </p>

              <p className={Cx.paragraph}>
                For an uncertainty to qualify, it doesnt mean that it was impossible to overcome or
                that it was super easy, but it must be non-straightforward or readily deducible for
                a competent professional, from the existing knowledge and capability within the
                industry. The key thing is we want to show that through doing this project, or
                attempting to overcome the uncertainties in the project, you were able to make an
                advancement by increasing or seeking to increase overall knowledge and capability
                within the field.
              </p>

              <p className={Cx.paragraph}>
                Please note that R&D for tax purposes takes place when a project seeks to achieve an
                advance in science or technology. Overall knowledge or capability in a field of
                science or technology can still be advanced (and hence R&D can still be done) in
                situations where:
              </p>

              <ul className={Cx.list}>
                <li>
                  a) Several companies are working at the cutting edge in the same field and are
                  doing similar work independently;
                </li>
                <li>
                  b) Work has already been done but this is not known in general because it is a
                  trade secret, and another company repeats the work;
                </li>
                <li>
                  c) It is known that a particular advance in science or technology has been
                  achieved, but the details of how are not readily available.
                </li>
              </ul>

              <p className={Cx.paragraph}>
                The activities which directly contribute to achieving this advance in science or
                technology through the resolution of scientific or technological uncertainty are
                R&D. Certain qualifying indirect activities related to the project are also R&D.
                Activities other than qualifying indirect activities which do not directly
                contribute to the resolution of the project’s scientific or technological
                uncertainty are not R&D.
              </p>

              <p className={Cx.paragraph}>Qualifying indirect activities include:</p>

              <ul className={Cx.list}>
                <li>
                  a) Scientific and technical information services, insofar as they are conducted
                  for the purpose of R&D support (such as the preparation of the original report of
                  R&D findings);
                </li>
                <li>
                  b) Indirect supporting activities such as maintenance, security, administration
                  and clerical activities, and finance and personnel activities, insofar as
                  undertaken for R&D;
                </li>
                <li>
                  c) Ancillary activities essential to the undertaking of R&D (e.g., taking on and
                  paying staff, leasing laboratories, and maintaining research and development
                  equipment, including computers used for R&D purposes);
                </li>
                <li>d) Training required to directly support an R&D project;</li>
                <li>e) Research by students and researchers carried out at universities;</li>
                <li>
                  f) Research (including related data collection) to devise new scientific or
                  technological testing, survey, or sampling methods, where this research is not R&D
                  in its own right; and
                </li>
                <li>
                  g) Feasibility studies to inform the strategic direction of a specific R&D
                  activity.
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-10 text-center m-auto">
            <Link className={`${Cx.button} mb-5`} to={"/technical-analysis"}>
              Next
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuestionInstructionScreen;
