import React, { useEffect, useRef, useState } from "react";
import Cx from "./page.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaRegEye } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { GrFormEdit } from "react-icons/gr";
import moment from "moment";
import { TbActivityHeartbeat, TbVideo } from "react-icons/tb";
import DefaultFooter from "examples/Footers/DefaultFooter";
import MKBox from "components/MKBox";
import footerRoutes from "footer.routes";
import Header from "layouts/hearder/Header";
import axios from "axios";
import Logo from "../../../assets/images/logoAg.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchGlobalEvents } from "../../../redux/slice/globalSlice";
import { Modal } from "react-bootstrap";

const Dashboard = () => {
  const fileInputRef = useRef(null);
  const userId = localStorage.getItem("userId");
  const [dashboardData, setDashboardData] = useState({});
  const [callLink, setCallLink] = useState();
  const [callAnswer, setCallAnswer] = useState();

  const [selectedFiles, setSelectedFiles] = useState({
    filedAccounts: null,
    filedCT600: null,
    taxComputation: null,
  });
  const dispatch = useDispatch();
  const { upcomingEvents } = useSelector((state) => state.global);
  const email = localStorage.getItem("email");

  const [loeData, setLoeData] = useState();
  const [workBookData, setWorkBookData] = useState();
  const [loeStatus, setLoeStatus] = useState();

  const fetchWorkEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}workapp/?user_id=${userId}`
      );
      if (response) {
        console.log(response.data, "workbook");
        setWorkBookData(response.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const fetchLoeEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}loeapp/?user_id=${userId}`
      );

      if (response) {
        setLoeData(response.data);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };
  useEffect(() => {
    if (email) {
      dispatch(fetchGlobalEvents(email));
    }
  }, [dispatch, email]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}user/${userId}/`);
      setDashboardData(response.data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const fetchDataLoeStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}document/?user_id=${userId}`
      );
      const taxLoeData =
        response?.data && response?.data?.results?.filter((event) => event.role === "tax");

      setLoeStatus(taxLoeData);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const handleLink = async () => {
    const apiMeetingData = await axios.get(`${process.env.REACT_APP_BASE_URL + "calendly/"}`);
    const meetingData = apiMeetingData?.data;
    const technicalCallLink = meetingData?.find((item) => item?.type === "technical");
    setCallLink(technicalCallLink?.link);
  };

  useEffect(() => {
    fetchData();
    fetchWorkEvents();
    fetchLoeEvents();
    fetchDataLoeStatus();
    handleLink();
  }, [userId]);

  const handleFileChange = (event, fileType) => {
    const file = event?.target?.files[0];
    if (file) {
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [fileType]: file,
      }));
      onFileUploadHandler({ ...selectedFiles, [fileType]: file });
    }
  };

  const handleViewPdf = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  const onFileUploadHandler = async (files) => {
    try {
      if (!userId) {
        console.error("User ID not found");
        return;
      }

      const formData = new FormData();
      if (files?.filedAccounts) {
        formData.append("Accounts", files?.filedAccounts);
      }
      if (files?.filedCT600) {
        formData.append("CT600", files?.filedCT600);
      }
      if (files?.taxComputation) {
        formData.append("Taxcomputation", files?.taxComputation);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}user/${userId}/`,
        formData,
        config
      );

      if (response.status === 200) {
        fetchData();

        setSelectedFiles({ filedAccounts: null, filedCT600: null, taxComputation: null });
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const triggerFileInput = (fileType) => {
    fileInputRef.current.setAttribute("data-file-type", fileType);
    fileInputRef.current.click();
  };
  const formatTime = (isoString) => {
    try {
      return moment(isoString).format("MMMM D, YYYY h:mm A");
    } catch (error) {
      console.error("Invalid date format", error);
      return "Invalid date";
    }
  };

  const taxLoeData = loeData && loeData?.filter((event) => event.role === "tax");

  const fetchAnswers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}answers/?user_id=${userId}&category=Technical%20Call%20Questionnaire`
      );
      const answers = response?.data?.data;
      console.log(answers, "answers,answers,answers");
      setCallAnswer(answers);
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };

  useEffect(() => {
    fetchAnswers();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  console.log("workBookData", workBookData);

  const steps = {
    "Estimated Tax Benefit For Year 2025: $8000": "estimated_tax_benefit",
    "AML Check Complete": "aml_check_complete",
    "Preparing Your Tax Relief Claim": "preparing_tax_claim",
    "Financial And Technical Elements Completed": "financial_and_technical_completed",
    "Final Sign Off Ready For Client Review": "final_sign_off_ready",
    "Final Sign Off Sent": "final_sign_off_sent",
    "Claim Filed": "claim_field",
    "Submission Receipt": "submission_receipt",
    "Claim Being Processed By HMRC": "claim_being_processed",
    "Claim Processed By HMRC": "claim_processed",
  };

  // Function to get the matching label
  const getStatusLabel = (status) => {
    return Object.keys(steps).find((key) => steps[key] === status) || "Status not found";
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className={Cx.whatarelooking}>
                <div className={Cx.mainheadingtop}>
                  <h1>Dashboard</h1>
                </div>
                <div className={Cx.dashboardForm}>
                  <div className={Cx.accounts}>
                    <div className={Cx.icondoc}>
                      <CgFileDocument />
                    </div>
                    <h2>Filed Accounts</h2>
                    <div className={Cx.iconsponts}>
                      {dashboardData?.Accounts && (
                        <span
                          onClick={() => handleViewPdf(dashboardData.Accounts)}
                          className={Cx.iconcircel}
                        >
                          <FaRegEye />
                        </span>
                      )}
                      <span
                        onClick={() => triggerFileInput("filedAccounts")}
                        style={{ position: "relative" }}
                      >
                        <GrFormEdit className={Cx.editcircelbtn} />
                      </span>
                    </div>
                  </div>
                  <div className={Cx.accounts2}>
                    <div className={Cx.icondoc}>
                      <CgFileDocument />
                    </div>
                    <div>
                      <h2>Filed CT600</h2>
                      <p style={{ textAlign: "center" }}>(Tax Return)</p>
                    </div>
                    <div className={Cx.iconsponts}>
                      {dashboardData?.CT600 && (
                        <span
                          onClick={() => handleViewPdf(dashboardData?.CT600)}
                          className={Cx.iconcircel}
                        >
                          <FaRegEye />
                        </span>
                      )}
                      <span
                        onClick={() => triggerFileInput("filedCT600")}
                        style={{ position: "relative" }}
                      >
                        <GrFormEdit className={Cx.editcircelbtn} />
                      </span>
                    </div>
                  </div>
                  <div className={Cx.accounts}>
                    <div className={Cx.icondoc}>
                      <CgFileDocument />
                    </div>
                    <h2>Tax Computation</h2>
                    <div className={Cx.iconsponts}>
                      {dashboardData?.Taxcomputation && (
                        <span
                          onClick={() => handleViewPdf(dashboardData?.Taxcomputation)}
                          className={Cx.iconcircel}
                        >
                          <FaRegEye />
                        </span>
                      )}
                      <span
                        onClick={() => triggerFileInput("taxComputation")}
                        style={{ position: "relative" }}
                      >
                        <GrFormEdit className={Cx.editcircelbtn} />
                      </span>
                    </div>
                  </div>

                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) =>
                      handleFileChange(e, fileInputRef.current.getAttribute("data-file-type"))
                    }
                    style={{ display: "none" }}
                  />

                  {dashboardData?.fso_status === "signed" ||
                  dashboardData?.fso_status === "sent" ? (
                    <div className={Cx.accounts}>
                      <div className={Cx.icondoc}>
                        <CgFileDocument />
                      </div>
                      <h2>Final Review and Sign Off</h2>
                      <div className={Cx.iconsponts}>
                        {dashboardData?.fso_status === "sent" && (
                          <span onClick={handleShowModal} className={Cx.iconcircel}>
                            <FaRegEye />
                          </span>
                        )}
                        {dashboardData?.fso_status === "signed" && <span>Signed</span>}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {workBookData && workBookData?.length > 0 && (
                    <div className="mt-3">
                      <div className={Cx.accounts6}>
                        <div className={Cx.icondoc}>
                          <CgFileDocument />
                        </div>
                        <div>
                          <Link to={workBookData[0]?.work_link} target="_blank">
                            <h2>
                              <b>WorkBook</b>
                            </h2>
                          </Link>
                        </div>
                        <div className={Cx.iconsponts}>
                          <span>Complete here</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {taxLoeData && taxLoeData.length > 0 && (
                    <div className="mt-3">
                      {taxLoeData &&
                        taxLoeData?.map((event) => (
                          <>
                            <div key={event.id} className={Cx.accounts3}>
                              <div className={Cx.icondoc}>
                                <CgFileDocument />
                              </div>
                              <div>
                                <h2>Letter of Engagement</h2>
                              </div>
                              <div className={Cx.iconsponts} style={{ width: "0px" }}>
                                <span>
                                  <Link to={event?.loe_link} target="_blank">
                                    <h5>
                                      {loeStatus &&
                                        loeStatus?.map((event, index) => (
                                          <span key={index}>
                                            {event?.signstatus === "signed"
                                              ? "Signed"
                                              : "Sign here"}
                                          </span>
                                        ))}
                                    </h5>
                                  </Link>
                                </span>
                              </div>
                            </div>
                            {dashboardData?.fso_status === "signed" ? (
                              <></>
                            ) : (
                              <div className={Cx.accounts5}>
                                <div className="mt-3">
                                  <p>
                                    <Link to={callLink} className={Cx.linkarea} target="_blank">
                                      Please click here to book your technical meeting with our team
                                      .
                                    </Link>
                                  </p>
                                  {callAnswer && (
                                    <>
                                      <p>Or</p>
                                      <p>
                                        <Link to={"/instruction"} className={Cx.linkarea}>
                                          Use our Smart feature to complete it Yourself by
                                          &apos;clicking here.&apos;
                                        </Link>
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  )}

                  {upcomingEvents.length > 0 && (
                    <div className="mt-5">
                      <div className={Cx.accounts4}>
                        <div className={Cx.icondoc}>
                          <TbVideo />
                        </div>
                        <div className={Cx.statustext}>
                          <h5>
                            <b>Next Meeting Time</b>
                          </h5>
                          <p>Start Time: {formatTime(upcomingEvents[0]?.start_time)}</p>
                          <Link to={upcomingEvents[0]?.location?.join_url} target="_blank">
                            Join Meeting Link
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mt-5">
                    <div className={Cx.accounts4}>
                      <div className={Cx.icondoc}>
                        <TbActivityHeartbeat />
                      </div>
                      <div className={Cx.statustext}>
                        <Link to={"/application-status"}>
                          <h5>
                            <b>Status</b>
                          </h5>
                          <p>{getStatusLabel(dashboardData?.tax_status)}</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        size="md"
        show={showModal}
        centered
        className="modal fade"
        aria-hidden="true"
        onHide={handleCloseModal}
      >
        <div className={Cx.popuarea}>
          <div className={Cx.logo}>
            <img src={Logo} alt="Logo" className={Cx.centeredLogo} />
          </div>

          <div className={Cx.modalActions}>
            <p>
              Please check your email inbox for a DocuSign with final review and signed document.
            </p>
          </div>
        </div>
      </Modal>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Dashboard;
