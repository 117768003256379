import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Cx from "./page.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLock,
  faEye,
  faEyeSlash,
  faCircleUser,
  faIndustry,
  faBuildingShield,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../redux/slice/authSlice";
import { showErrorToast, showSuccessToast } from "../../../utils/Toster";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const actionResult = await dispatch(registerUser(data));
      if (registerUser.fulfilled.match(actionResult)) {
        showSuccessToast("Successfully registered");
        if (actionResult?.payload?.id !== null && actionResult?.payload?.id !== undefined) {
          localStorage.setItem("userId", actionResult?.payload?.id);
          localStorage.setItem("email", actionResult?.payload?.email);
          navigate("/Lookingfor");
        }
      } else {
        showErrorToast(actionResult?.payload?.email[0]);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      showErrorToast("An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };
  const password = watch("password");
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 m-auto">
            <div className="col-lg-12 mt-5">
              <div className={Cx.mainheading}>
                <h2>Signup</h2>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={Cx.signupForm}>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faCircleUser} className={Cx.icon} />
                  <input
                    type="text"
                    placeholder="Full Name"
                    {...register("full_name", {
                      required: "Full Name is required",
                    })}
                  />
                </div>
                {errors.full_name && <p className={Cx.errorMessage}>{errors.full_name.message}</p>}
              </div>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faBuildingShield} className={Cx.icon} />
                  <input
                    type="text"
                    placeholder="Company Name"
                    {...register("company_name", {
                      required: "Company Name is required",
                    })}
                  />
                </div>
                {errors.company_name && (
                  <p className={Cx.errorMessage}>{errors.company_name.message}</p>
                )}
              </div>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faEnvelope} className={Cx.icon} />
                  <input
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[\w.-]+@([\w-]+\.)+\w{2,4}$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </div>
                {errors.email && <p className={Cx.errorMessage}>{errors.email.message}</p>}
              </div>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faPhone} className={Cx.icon} />
                  <input
                    type="tel"
                    placeholder="Mobile Number"
                    {...register("mobile_no", {
                      required: "Mobile number is required",
                    })}
                  />
                </div>
                {errors.mobile_no && <p className={Cx.errorMessage}>{errors.mobile_no.message}</p>}
              </div>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faIndustry} className={Cx.icon} />
                  <input
                    type="text"
                    placeholder="Industry Type"
                    {...register("industry", {
                      required: "Industry is required",
                    })}
                  />
                </div>
                {errors.industry && <p className={Cx.errorMessage}>{errors.industry.message}</p>}
              </div>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faLock} className={Cx.icon} />
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters",
                      },
                    })}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className={Cx.toggleIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
                {errors.password && <p className={Cx.errorMessage}>{errors.password.message}</p>}
              </div>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faLock} className={Cx.icon} />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    {...register("password2", {
                      required: "Please confirm your password",
                      validate: (value) => value === password || "Passwords do not match",
                    })}
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                    className={Cx.toggleIcon}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
                {errors.password2 && <p className={Cx.errorMessage}>{errors.password2.message}</p>}
              </div>
              <div className={Cx.mainheading}>
                <button type="submit" className={Cx.submitButton2} disabled={loading}>
                  {loading ? "Signing Up..." : "Sign Up"}
                </button>
              </div>

              <div className={Cx.mainheading}>
                <p>
                  Already have an account?
                  <br />
                  <span>
                    <Link to={"/signin"} className={Cx.linkbtn}>
                      Login!
                    </Link>
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
