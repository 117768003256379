import React, { useState } from "react";
import Cx from "./page.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { Calendar, DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/teal.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const BookSession = () => {
  const [dates, setDates] = useState([new DateObject()]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={Cx.accounts}>
              <Link to="/Lookingfor" className="backbtn">
                <IoArrowBackOutline />
              </Link>
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <div className={Cx.whatarelooking}>
              <div className={Cx.mainheadinglookingfor}>
                <h5>
                  Book a session with finance <br />
                  expert now!
                </h5>
              </div>
              <div className={Cx.UploaddocumentForm}>
                <div style={{ display: "grid", placeItems: "center" }}>
                  <Calendar value={dates} onChange={setDates} multiple />
                </div>
                <div className="mt-5">
                  <button onClick={handleOpen} className="submitButton">
                    Schedule Meeting
                  </button>
                </div>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className={Cx.popuarea}>
                    <Typography>
                      <p onClick={handleClose}>
                        Thanks for scheduling a meeting we will get back to you on scheduled date
                        and time.
                      </p>
                      <p>
                        <Link
                          className="linkbtn"
                          style={{ color: "#fff", fontSize: "18px" }}
                          to="/Lookingfor/Dashboard"
                        >
                          Cheers
                        </Link>
                      </p>
                    </Typography>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookSession;
