import React, { useEffect, useState } from "react";
import Cx from "./page.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Header from "layouts/hearder/Header";
import MKBox from "components/MKBox";
import { PiSpeakerHighDuotone, PiSpeakerSlashDuotone } from "react-icons/pi";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import MicRecorder from "mic-recorder-to-mp3";
import { FiMic, FiMicOff, FiTrash } from "react-icons/fi";

const TechnicalAnalysis = () => {
  const [open, setOpen] = useState(false);
  const [technicalCallQuestionnaire, setTechnicalCallQuestionnaire] = useState([]);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = useState(false);
  const [projectName, setProjectName] = useState(false);
  const id = localStorage.getItem("userId");
  const [activeMicIndex, setActiveMicIndex] = useState(null);
  const [activeSpeakIndex, setActiveSpeakIndex] = useState(null);
  const [recorders, setRecorders] = useState({});
  const [audioFiles, setAudioFiles] = useState({});
  const [audioPlayers, setAudioPlayers] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const [setRecordingStatus] = useState({});
  const [answerDataCount, setAnswersData] = useState(1);
  const [qustonCheck, set21QustonCheck] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const project = location?.state;
  const [allQuestionData, setQuestionData] = useState([]);
  const [projectData, setProjectData] = useState();

  const fetchProject = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}project/?user_id=${id}`);
      setProjectData(response?.data);
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };
  const handleOpen1 = () => {
    console.log(projectData, "projectData");

    setOpen1(true);
  };
  const fetchAnswers = async () => {
    try {
      const projectIds = localStorage.getItem("project");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}answers/?user_id=${id}&category=Technical%20Call%20Questionnaire&project=${projectIds}`
      );
      if (response?.data) {
        const answers = response?.data?.data;
        console.log(answers, "projectIdsprojectIds");
      }
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const projectid = await localStorage.getItem("project");
      if (projectid == null || project === "OpenModel") {
        handleOpen1();
      }
    };

    fetchData();
    fetchProject();
  }, []);

  const handleClose1 = () => {
    setOpen1(false);
  };
  const fetchDocuments = async () => {
    setTechnicalCallQuestionnaire([]);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}question/?user_type=taxrelief&category=Technical%20Call%20Questionnaire`
      );

      if (qustonCheck === "StartAgain") {
        const qusData = await response?.data
          .filter((item) => item?.startagainrange?.length > 0)
          .map((item) => item?.startagainrange);

        const filteredData = await response?.data?.filter((item) => {
          const order = item?.order;
          return qusData?.some((rangeArray) =>
            rangeArray?.some(
              (range) => order >= parseInt(range?.start) && order <= parseInt(range?.end)
            )
          );
        });
        setQuestionData(response?.data);
        setTechnicalCallQuestionnaire(filteredData);
      } else {
        setQuestionData(response?.data);
        setTechnicalCallQuestionnaire(response?.data);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
    fetchAnswers();
  }, []);
  useEffect(() => {
    fetchDocuments();
  }, [qustonCheck]);

  const projectCreate = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}project/`,
        {
          user: localStorage.getItem("userId"),
          name: projectName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        handleClose1();
        localStorage.setItem("project", response?.data?.id);
      }
      return response.data;
    } catch (error) {
      console.error("Error creating project:", error);
      throw error;
    }
  };

  const handleMicToggle = (index) => {
    if (activeMicIndex !== null && activeMicIndex !== index) {
      stopRecording(activeMicIndex);
    }

    if (activeMicIndex === index) {
      stopRecording(index);
      setActiveMicIndex(null);
    } else {
      startRecording(index);
      setActiveMicIndex(index);
      setActiveSpeakIndex(null);
    }
  };

  const handleSpeakToggle = (index) => {
    if (activeSpeakIndex !== null && activeSpeakIndex !== index) {
      stopSpeaking(activeSpeakIndex);
    }

    if (activeSpeakIndex === index) {
      stopSpeaking(index);
      setActiveSpeakIndex(null);
    } else if (audioFiles[index]) {
      startSpeaking(index);
      setActiveSpeakIndex(index);
      setActiveMicIndex(null);
    }
  };

  const startRecording = (index) => {
    const recorder = new MicRecorder({ bitRate: 128 });
    recorder
      .start()
      .then(() => {
        setRecorders((prevRecorders) => ({
          ...prevRecorders,
          [index]: recorder,
        }));
      })
      .catch((e) => console.error(e));
  };

  const stopRecording = (index) => {
    recorders[index]
      ?.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, `question${index}.mp3`, {
          type: blob.type,
          lastModified: Date.now(),
        });
        setAudioFiles((prevFiles) => ({
          ...prevFiles,
          [index]: file,
        }));
        setRecordingStatus((prev) => ({
          ...prev,
          [index]: "Recording complete!",
        }));
      })
      .catch((e) => console.error(e));
  };

  const startSpeaking = (index) => {
    const player = new Audio(URL.createObjectURL(audioFiles[index]));
    player.play();
    setAudioPlayers((prev) => ({ ...prev, [index]: player }));
    player.onended = () => {
      setActiveSpeakIndex(null);
      setAudioPlayers((prev) => {
        const newState = { ...prev };
        delete newState[index];
        return newState;
      });
    };
  };

  const stopSpeaking = (index) => {
    const player = audioPlayers[index];
    if (player) {
      player.pause();
      player.currentTime = 0;
    }
  };

  const deleteRecording = (index) => {
    setAudioFiles((prevFiles) => {
      const newFiles = { ...prevFiles };
      delete newFiles[index];
      return newFiles;
    });
    setRecordingStatus((prev) => {
      const newStatus = { ...prev };
      delete newStatus[index];
      return newStatus;
    });
  };

  const handleTextChange = (index, event) => {
    setTextAnswers((prev) => ({
      ...prev,
      [index]: event.target.value,
    }));
  };

  const uploadAudioFile = async (audioFile) => {
    const formData = new FormData();
    formData.append("audio_file", audioFile);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}audio/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response?.data;
    } catch (error) {
      console.error("Error uploading audio file:", error);
      throw error;
    }
  };

  const handelYes = async (status) => {
    const dataAnswerLocal = localStorage.getItem("answerData");
    const answerData = await Promise.all(
      technicalCallQuestionnaire.map(async (item, index) => {
        let audioLink = null;
        if (audioFiles[index]) {
          const s3Link = await uploadAudioFile(audioFiles[index]);
          audioLink = s3Link?.audio_file;
        }
        return {
          user: localStorage.getItem("userId"),
          question: item?.id,
          answer: textAnswers?.[index] ? [textAnswers[index]] : audioLink ? [audioLink] : [],
          project: localStorage.getItem("project"),
        };
      })
    );
    setAnswersData((prevState) => prevState + 1);
    if (answerDataCount <= 1) {
      console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAa");
      localStorage.setItem("answerData", JSON.stringify(answerData));
      if (status === "yes") {
        console.log("YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYyy");
        set21QustonCheck("StartAgain");
        fetchDocuments();
        fetchAnswers();
      } else {
        const dataAnswerLocaLs = localStorage.getItem("answerData");
        handleSubmit(dataAnswerLocaLs);
      }
    } else if (answerDataCount !== 1 && answerDataCount < 6) {
      const newAnswerData = JSON.parse(dataAnswerLocal);
      const payloads = allQuestionData?.map((item) => {
        const id = item?.id;
        const existingPayload = newAnswerData?.find((payload) => payload?.question === id) || {};
        const answerIndex = answerData.findIndex((ans) => ans?.question === id);
        const existingAnswers = existingPayload?.answer || [];
        const newAnswer = answerData?.[answerIndex]?.answer[0] || "";
        const formattedAnswer = newAnswer ? [...existingAnswers, newAnswer] : existingAnswers;
        return {
          ...existingPayload,
          answer: formattedAnswer,
          user: localStorage.getItem("userId"),
          count: formattedAnswer?.length,
          project: localStorage.getItem("project"),
        };
      });
      localStorage.setItem("answerData", JSON.stringify(payloads));
      console.log("UUUUUUUUUUUUUUUUUUUUUUUUUUUUU");
      if (status === "yes") {
        set21QustonCheck("StartAgain");
        fetchDocuments();
        fetchAnswers();
      } else {
        console.log("LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL");
        const dataAnswerLocaLs = localStorage.getItem("answerData");
        handleSubmit(dataAnswerLocaLs);
      }
    } else {
      console.log("L2222222222222222222222222222222222222");
      const dataAnswerLocaLs = localStorage.getItem("answerData");
      handleSubmit(dataAnswerLocaLs);
    }
  };

  const handleSubmit = async (dataAnswerLocaLs) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}bulkanswer/`,
        dataAnswerLocaLs,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response) {
        fetchDocuments();
        fetchAnswers();
        set21QustonCheck();
        navigate("/project-document");
        localStorage.removeItem("answerData");
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className={Cx.mainheadingtop}>
                <p>
                  Let’s do some technical analysis by <br /> answering a few questions!
                </p>
              </div>

              <form className={Cx.TechnicalanalysisForm}>
                {technicalCallQuestionnaire &&
                  technicalCallQuestionnaire
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => (
                      <>
                        <div className={Cx.formGroup} key={index}>
                          <label htmlFor={`income${item}`}>
                            <span>
                              Q.{item?.order}.{item?.partno == 1 ? "" : item?.partno}
                            </span>
                            {item?.question}
                          </label>
                          <div className={Cx.inputGroup}>
                            {item?.responseType === "Mcq" ? (
                              <div className={Cx.customTextarea}>
                                <div className={Cx.radioGroup}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name={item?.choice1}
                                      value={item?.choice1}
                                      required
                                      id="html"
                                      style={{ width: "20px" }}
                                      onChange={(e) => handleTextChange(index, e)}
                                    />
                                    <span>{item?.choice1}</span>
                                  </label>
                                </div>
                                <div className={Cx.radioGroup}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name={item?.choice2}
                                      value={item?.choice2}
                                      required
                                      id="html"
                                      style={{ width: "20px" }}
                                      onChange={(e) => handleTextChange(index, e)}
                                    />
                                    <span>{item?.choice2}</span>
                                  </label>
                                </div>
                                <div className={Cx.radioGroup}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name={item?.choice3}
                                      value={item?.choice3}
                                      required
                                      id="html"
                                      style={{ width: "20px" }}
                                      onChange={(e) => handleTextChange(index, e)}
                                    />
                                    <span>{item?.choice3}</span>
                                  </label>
                                </div>
                                <div className={Cx.radioGroup}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name={item?.choice4}
                                      value={item?.choice4}
                                      required
                                      id="html"
                                      style={{ width: "20px" }}
                                      onChange={(e) => handleTextChange(index, e)}
                                    />
                                    <span>{item?.choice4}</span>
                                  </label>
                                </div>
                              </div>
                            ) : item?.responseType === "Text/Audio" ? (
                              <div className={Cx.inputGroup}>
                                <textarea
                                  id={`income${item}`}
                                  placeholder={item?.question}
                                  onChange={(e) => handleTextChange(index, e)}
                                  required
                                />
                                <div className={Cx.toggleIcon}>
                                  <div onClick={() => handleMicToggle(index)}>
                                    {activeMicIndex === index ? <FiMic /> : <FiMicOff />}
                                  </div>
                                  {audioFiles[index] && (
                                    <div onClick={() => handleSpeakToggle(index)}>
                                      {activeSpeakIndex === index ? (
                                        <PiSpeakerSlashDuotone />
                                      ) : (
                                        <PiSpeakerHighDuotone />
                                      )}
                                    </div>
                                  )}
                                  {audioFiles[index] && (
                                    <div onClick={() => deleteRecording(index)}>
                                      <FiTrash />
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className={Cx.formGroup}>
                                <div className={Cx.customTextarea2} id="income3" role="textbox">
                                  <div className="row" style={{ marginRight: "0px" }}>
                                    <div className="col-md-6">
                                      <div className={Cx.radioGroup}>
                                        <label
                                          onChange={(e) => {
                                            handleTextChange(index, e);
                                            handelYes("yes");
                                          }}
                                        >
                                          <input type="radio" name="income" value="yes" />
                                          <span>yes</span>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className={Cx.radioGroup}>
                                        <label
                                          onChange={(e) => {
                                            handleTextChange(index, e);
                                            handelYes("no");
                                          }}
                                        >
                                          <input type="radio" name="income" value="no" />
                                          <span>No</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
              </form>

              <div className={Cx.mainheading}>
                <div className="mb-5">
                  <button className={Cx.submitButton2}>Submit</button>
                </div>
                <p>Haven’t uploaded the documents?</p>
                <p>
                  <span>
                    <Link to="/upload-document" className={Cx.linkbtn}>
                      Upload Now!
                    </Link>
                  </span>
                </p>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  style={{
                    opacity: "0.9",
                    background: "#0B284D",
                    transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)",
                  }}
                  aria-describedby="modal-modal-description"
                >
                  <Box className={Cx.popuarea}>
                    <Typography>
                      <p onClick={handleClose}>
                        We have received your Discovery questionnaire. Our team will soon connect
                        with you.
                        <br />
                        Or
                      </p>
                      <p>
                        <Link className={Cx.linkbtn2} to="/Lookingfor/Booksession">
                          You can book a discovery call by clicking here!
                        </Link>
                      </p>
                    </Typography>
                  </Box>
                </Modal>
              </div>
              <div className={Cx.mainheading}>
                <Modal
                  open={open1}
                  onClose={handleClose1}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  disableEscapeKeyDown={true}
                  BackdropProps={{
                    onClick: (e) => e.stopPropagation(),
                  }}
                  style={{
                    opacity: "0.9",
                    background: "#0B284D",
                    transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)",
                  }}
                >
                  <Box className={Cx.popuarea2}>
                    <div className={Cx.formGroup}>
                      <lable>What is your project name ?</lable>
                      <div className={Cx.inputGroup}>
                        <FontAwesomeIcon icon={faBuilding} className={Cx.icon} />
                        <input
                          type="address"
                          id="projectname"
                          placeholder="Project Name"
                          required
                          onChange={(e) => setProjectName(e.target.value)}
                        />
                      </div>
                    </div>
                    <button className={Cx.submitButton2} onClick={projectCreate}>
                      Submit
                    </button>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default TechnicalAnalysis;
