import Icon from "@mui/material/Icon";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Dashboard from "pages/LandingPages/dashboard/Dashboard";
import DashboardBusiness from "pages/LandingPages/dashboardbusiness/DashboardBusiness";
import PrivacyPolicy from "pages/LandingPages/PrivacyPolicy";
import SignInOld from "pages/LandingPages/signinold/SignIn";
import TermsCondition from "pages/LandingPages/TermsConditions";

const getRoutes = () => {
  const token = localStorage.getItem("userId");
  const UserTypes = localStorage.getItem("UserType");

  if (token) {
    return [
      {
        name: "Dashboard",
        icon: <Icon>dashboard</Icon>,
        route: UserTypes === "is_business" ? "/dashboard-business" : "/dashboard",
        component: UserTypes === "is_business" ? <DashboardBusiness /> : <Dashboard />,
      },
      {
        name: "pages",
        icon: <Icon>view_day</Icon>,
        collapse: [
          {
            name: "contact support",
            route: "/pages/landing-pages/contact-support",
            component: <ContactUs />,
          },
          {
            name: "terms & conditions",
            route: "/pages/landing-pages/terms&condition",
            component: <TermsCondition />,
          },
          {
            name: "privacy & policy",
            route: "/pages/landing-pages/privacy&policy",
            component: <PrivacyPolicy />,
          },
        ],
      },
    ];
  } else {
    return [
      {
        name: "Login",
        icon: <Icon>login</Icon>,
        route: "/signIn",
        component: <SignInOld />,
      },
      {
        name: "pages",
        icon: <Icon>view_day</Icon>,
        collapse: [
          {
            name: "contact support",
            route: "/pages/landing-pages/contact-support",
            component: <ContactUs />,
          },
          {
            name: "terms & conditions",
            route: "/pages/landing-pages/terms&condition",
            component: <TermsCondition />,
          },
          {
            name: "privacy & policy",
            route: "/pages/landing-pages/privacy&policy",
            component: <PrivacyPolicy />,
          },
        ],
      },
    ];
  }
};

const routes = getRoutes();

export default routes;
