import React, { useEffect, useState } from "react";
import Cx from "./page.module.scss";
import { IoArrowBackOutline } from "react-icons/io5";
import Header from "layouts/hearder/Header";
import MKBox from "components/MKBox";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { Link } from "react-router-dom";
import axios from "axios";

const ProjectList = () => {
  const [projectData, setProjectData] = useState();
  const id = localStorage.getItem("userId");

  const fetchAnswers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}project/?user_id=${id}`);
      setProjectData(response?.data);
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };

  useEffect(() => {
    fetchAnswers();
  }, []);

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 m-auto">
              <div className={Cx.projectlistpage}>
                <div className={Cx.projectlisttop}>
                  <Link to={"/dashboard"} className={Cx.backbtn}>
                    <IoArrowBackOutline />
                  </Link>
                  <div className={Cx.introText}>
                    <h2>Project List</h2>
                  </div>
                </div>
                <div className={Cx.projectListform}>
                  {projectData &&
                    projectData?.map((item) => {
                      return (
                        <div className={Cx.mainheading} key={item.id}>
                          <Link
                            to={`/technical-analysis-edit/${item.id}`}
                            className={Cx.submitButton11}
                          >
                            {item.name}
                          </Link>
                        </div>
                      );
                    })}
                  {projectData?.length < 5 && (
                    <div className={Cx.mainheading}>
                      <Link
                        to={"/technical-analysis"}
                        state={"OpenModel"}
                        className={Cx.submitButton2}
                      >
                        Add more projects
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default ProjectList;
