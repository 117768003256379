import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Cx from "./page.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { resetPassword } from "../../../redux/slice/authSlice";
import { showSuccessToast } from "../../../utils/Toster";
import { showErrorToast } from "../../../utils/Toster";
import { useDispatch } from "react-redux";

const ResetPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      const newData = {
        email: localStorage.getItem("email"),
        password: data?.password,
        password2: data?.confirmPassword,
      };
      const actionResult = await dispatch(resetPassword(newData));
      if (resetPassword.fulfilled.match(actionResult)) {
        showSuccessToast("Password Forgot Successfully");
        if (actionResult?.payload) {
          localStorage.removeItem("userId", actionResult?.payload?.id);
          localStorage.removeItem("email", actionResult?.payload?.email);
        }
        navigate("/signin");
      } else {
        showErrorToast(actionResult.payload.email[0]);
      }
    } catch (error) {
      showErrorToast("An unexpected error occurred");
    }
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-5 mb-5">
            <div className={Cx.accounts}>
              <Link to="/forget-password" className={Cx.backbtn}>
                <IoArrowBackOutline />
              </Link>
              <div className={Cx.mainheading}>
                <h2>Reset Password</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <form onSubmit={handleSubmit(onSubmit)} className={Cx.ResetpasswordForm}>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faLock} className={Cx.icon} />
                  <input
                    type={showPassword ? "text" : "password"}
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters",
                      },
                    })}
                    placeholder="New Password"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className={Cx.toggleIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
                {errors.password && <p className={Cx.errorMessage}>{errors.password.message}</p>}
              </div>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faLock} className={Cx.icon} />
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    {...register("confirmPassword", {
                      required: "Please confirm your password",
                      validate: (value) => value === watch("password") || "Passwords do not match",
                    })}
                    placeholder="Confirm Password"
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                    className={Cx.toggleIcon}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
                {errors.confirmPassword && (
                  <p className={Cx.errorMessage}>{errors.confirmPassword.message}</p>
                )}
              </div>

              <div className={Cx.mainheading}>
                <button type="submit" className={Cx.submitButton2}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
