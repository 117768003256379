import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Cx from "./page.module.scss";
import { useNavigate } from "react-router-dom";
import { FiMic, FiMicOff, FiTrash } from "react-icons/fi";
import { PiSpeakerHighDuotone, PiSpeakerSlashDuotone } from "react-icons/pi";
import Header from "layouts/hearder/Header";
import MKBox from "components/MKBox";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import axios from "axios";
import MicRecorder from "mic-recorder-to-mp3";
import { showSuccessToast } from "utils/Toster";

const DiscoveryAnalysis = () => {
  const [discoveryCallQuestionnaire, setDiscoveryCallQuestionnaire] = useState([]);
  const [discoveryCallAnswer, setDiscoveryCallAnswer] = useState([]);
  const [recorders, setRecorders] = useState({});
  const [audioPlayers, setAudioPlayers] = useState({});
  const [recordingStatus, setRecordingStatus] = useState({});
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const { control, handleSubmit, setValue, watch } = useForm();

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}question/?user_type=taxrelief&category=Discovery%20Call%20Questionnaire`
      );
      setDiscoveryCallQuestionnaire(response?.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const fetchAnswers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}answers/?user_id=${userId}&category=Discovery%20Call%20Questionnaire`
      );
      const answers = response?.data?.data;
      setDiscoveryCallAnswer(answers);
      answers.forEach((ans) =>
        setValue(
          `answer_${ans?.question?.id}`,
          containsAudioFileFormat(ans?.answer[0])
            ? {
                type: "audio",
                mic: false,
                speak: containsAudioFileFormat(ans?.answer[0]),
                delete: containsAudioFileFormat(ans?.answer[0]),
                value: ans?.answer,
                extra: ans,
              }
            : {
                type: "text",
                value: ans?.answer,
                extra: ans,
              }
        )
      );
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
    fetchAnswers();
  }, []);

  // Mic and audio controls
  const handleMicToggle = (index) => {
    const answerIndex = watch(`answer_${index}`);
    if (answerIndex?.mic) {
      stopRecording(index);
    } else {
      startRecording(index);
      setValue(`answer_${index}`, {
        ...answerIndex,
        mic: true,
        speak: false,
        delete: false,
      });
    }
  };

  const handleSpeakToggle = (index) => {
    const answerIndex = watch(`answer_${index}`);
    if (answerIndex?.speak) {
      console.log(answerIndex, "handle SPeak Toggle start");
      startSpeaking(index);
    } else {
      console.log(answerIndex, "handle SPeak Toggle stop");
      stopSpeaking(index);
    }
  };

  const startRecording = (index) => {
    const recorder = new MicRecorder({ bitRate: 128 });
    recorder
      .start()
      .then(() => {
        setRecorders((prevRecorders) => ({
          ...prevRecorders,
          [index]: recorder,
        }));
      })
      .catch((e) => console.error(e));
  };

  const stopRecording = (index) => {
    const answerIndex = watch(`answer_${index}`);
    recorders[index]
      ?.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, `question${index}.mp3`, {
          type: blob.type,
          lastModified: Date.now(),
        });
        setValue(`answer_${index}`, {
          ...answerIndex,
          value: file,
          speak: true,
          delete: true,
          mic: false,
        });

        setRecordingStatus((prev) => ({
          ...prev,
          [index]: "Recording complete!",
        }));
      })
      .catch((e) => console.error(e));
  };

  const startSpeaking = (index) => {
    const answerIndex = watch(`answer_${index}`);
    setValue(`answer_${index}`, {
      ...answerIndex,
      speak: false,
      mic: false,
      delete: true,
    });
    const player = new Audio(answerIndex?.value?.[0] ?? URL.createObjectURL(answerIndex?.value));
    player.play();
    setAudioPlayers((prev) => ({ ...prev, [index]: player }));
    player.onended = () => {
      setValue(`answer_${index}`, {
        ...answerIndex,
        speak: true,
        mic: false,
        delete: true,
      });
      setAudioPlayers((prev) => {
        const newState = { ...prev };
        delete newState[index];
        return newState;
      });
    };
  };

  const stopSpeaking = (index) => {
    const answerIndex = watch(`answer_${index}`);
    const player = audioPlayers[index];
    if (player) {
      player.pause();
      player.currentTime = 0;
    }
    setValue(`answer_${index}`, {
      ...answerIndex,
      speak: true,
      mic: false,
      delete: true,
    });
  };

  const deleteRecording = (index) => {
    const answerIndex = watch(`answer_${index}`);
    setValue(`answer_${index}`, {
      ...answerIndex,
      value: null,
      mic: false,
      speak: false,
      delete: false,
    });

    setRecordingStatus((prev) => {
      const newStatus = { ...prev };
      delete newStatus[index];
      return newStatus;
    });
  };

  const uploadAudioFile = async (audioFile) => {
    const formData = new FormData();
    formData.append("audio_file", audioFile);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}audio/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading audio file:", error);
      throw error;
    }
  };

  function containsAudioFileFormat(str) {
    const audioFileExtensions = [
      ".mp3",
      ".wav",
      ".ogg",
      ".aac",
      ".flac",
      ".m4a",
      ".wma",
      ".aiff",
      ".opus",
    ];
    if (typeof str !== "string") {
      return false;
    }
    const trimmedStr = str?.trim().toLowerCase();
    return audioFileExtensions.some((ext) => trimmedStr?.endsWith(ext));
  }

  const onSubmit = async (data) => {
    try {
      const answerData = await Promise.all(
        discoveryCallQuestionnaire &&
          discoveryCallQuestionnaire?.map(async (item) => {
            let audioLink = null;
            console.log(data, "datadata");
            const question_ans = data[`answer_${item?.id}`];
            console.log("question_ans", question_ans);
            if (question_ans?.value instanceof File) {
              const s3Link = await uploadAudioFile(question_ans?.value);
              audioLink = s3Link?.audio_file;
            }
            console.log(question_ans, "question_ans?.extra?.id");
            return {
              id: question_ans?.extra?.id,
              user: userId,
              question: item?.id,
              answer:
                Array.isArray(question_ans?.value) && question_ans?.value.length > 0
                  ? [question_ans?.value[0]]
                  : audioLink
                  ? [audioLink]
                  : [],
            };
          })
      );
      console.log(answerData, "answerData,answerData");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}bulkupdateanswer/`,
        answerData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response) {
        if (discoveryCallAnswer?.length > 0) {
          navigate("/dashboard");
        } else {
          navigate("/upload-document");
        }
        showSuccessToast(response.data.message);
        fetchAnswers();
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className={Cx.mainheadingUploaddocument}>
                <p>Discovery Analysis</p>
              </div>
              <form className={Cx.TechnicalanalysisForm} onSubmit={handleSubmit(onSubmit)}>
                {discoveryCallQuestionnaire &&
                  discoveryCallQuestionnaire.map((item, index) => {
                    return (
                      <div className={Cx.formGroup} key={index}>
                        <label htmlFor={`income${item.id}`}>
                          <span>
                            Q.{item?.order}.{item?.partno === 1 ? "" : item?.partno}
                          </span>{" "}
                          {item?.question}
                        </label>
                        <div className={Cx.inputGroup}>
                          <Controller
                            name={`answer_${item.id}`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <textarea
                                {...field}
                                id={`income${item.id}`}
                                placeholder={item?.question}
                                value={
                                  containsAudioFileFormat(field?.value?.value?.[0])
                                    ? ""
                                    : field?.value?.value?.[0] ?? field?.value[0]
                                }
                                onChange={(event) => {
                                  console.log(field.value, "field.value");
                                  field.onChange([event.target.value]);
                                }}
                              />
                            )}
                          />
                          <div className={Cx.toggleIcon}>
                            <div onClick={() => handleMicToggle(item.id)}>
                              {watch(`answer_${item.id}`)?.mic ? <FiMic /> : <FiMicOff />}
                            </div>
                            {watch(`answer_${item.id}`)?.delete && (
                              <div
                                onClick={() => {
                                  handleSpeakToggle(item?.id);
                                }}
                              >
                                {watch(`answer_${item.id}`)?.speak ? (
                                  <PiSpeakerSlashDuotone />
                                ) : (
                                  <PiSpeakerHighDuotone />
                                )}
                              </div>
                            )}

                            {watch(`answer_${item.id}`)?.delete && (
                              <div onClick={() => deleteRecording(item?.id)}>
                                <FiTrash />
                              </div>
                            )}
                          </div>
                        </div>
                        {recordingStatus[index] && <p>{recordingStatus[index]}</p>}
                      </div>
                    );
                  })}
                <div className={Cx.mainheading}>
                  <div className="mb-5">
                    <button type="submit" className={Cx.submitButton2}>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default DiscoveryAnalysis;
