import React from "react";
import { useForm } from "react-hook-form";
import Cx from "./page.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../utils/Toster";
import { resetPassword } from "../../../redux/slice/authSlice";

const ForgetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      const actionResult = await dispatch(resetPassword(data));
      if (resetPassword.fulfilled.match(actionResult)) {
        navigate("/otp-verification");
        console.log("actionResult?.payload", actionResult?.payload);
        localStorage.setItem("email", data?.email);
        showSuccessToast("Otp Successfully");
      } else {
        showErrorToast("Otp failed:", actionResult.payload);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-5 mb-5">
            <div className={Cx.accounts}>
              <Link to="/signin" className={Cx.backbtn}>
                <IoArrowBackOutline />
              </Link>
              <div className={Cx.mainheading}>
                <h2>Forgot Password</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-6 m-auto">
            <form onSubmit={handleSubmit(onSubmit)} className={Cx.ForgetpasswordForm}>
              <div className={Cx.formGroup}>
                <div className={Cx.inputGroup}>
                  <FontAwesomeIcon icon={faEnvelope} className={Cx.icon} />
                  <input
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Please enter a valid email address",
                      },
                    })}
                    placeholder="Email"
                  />
                </div>
                {errors.email && <p className={Cx.errorMessage}>{errors.email.message}</p>}
              </div>

              <div className={Cx.mainheading}>
                <button type="submit" className={Cx.submitButton2}>
                  Submit
                </button>
              </div>
              <div className={Cx.mainheading}>
                <p>We will share a reset password link to the email if it is registered with us!</p>
              </div>
              <div className={Cx.mainheading}>
                <p>
                  Don&apos;t have an account?
                  <br />
                  <span>
                    <Link to="/register" className={Cx.linkbtn}>
                      Sign Up!
                    </Link>
                  </span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
