import React, { useState } from "react";
import Cx from "../../../style/custom.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Box, Modal } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
//import { IoArrowBackOutline } from "react-icons/io5";

const DeleteAccount = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [errors, setErrors] = useState({ email: "", subject: "" });
  const [loading, setLoading] = useState(false);
  const [showSubject, setShowSubject] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // Form validation logic
  const validateForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Email is invalid.";

    if (!subject) newErrors.subject = "Password is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}deleteuser/`,
        {
          email,
          password: subject,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        navigate("/home");
        localStorage.removeItem("email");
        localStorage.removeItem("userId");
        localStorage.removeItem("UserType");
        window.location.reload();
      }
      console.log("API response:", response.data);
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <section className={Cx.contactform}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-10">
              <div className={Cx.accounts}>
                <Link to="/home" className={Cx.backbtn}>
                  <IoArrowBackOutline />
                </Link>
                <div className={Cx.mainheading}>
                  <h2>Delete Account</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-6 m-auto">
              <form className={Cx.SigninForm}>
                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <FontAwesomeIcon icon={faEnvelope} className={Cx.icon} />
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      required
                    />
                  </div>
                  {errors.email && <p className={Cx.errorMessage}>{errors.email}</p>}
                </div>

                {/* Password (Subject) Field with toggle visibility */}
                <div className={Cx.formGroup}>
                  <div className={Cx.inputGroup}>
                    <FontAwesomeIcon icon={faLock} className={Cx.icon} />
                    <input
                      type={showSubject ? "text" : "password"}
                      id="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      placeholder="Password"
                      required
                    />
                    <FontAwesomeIcon
                      icon={showSubject ? faEyeSlash : faEye}
                      className={Cx.toggleIcon}
                      onClick={() => setShowSubject(!showSubject)}
                    />
                  </div>
                  {errors.subject && <p className={Cx.errorMessage}>{errors.subject}</p>}
                </div>

                {/* Submit Button */}
                <div className="mt-5 text-center">
                  <button
                    type="button"
                    className={Cx.submitButton2}
                    disabled={loading}
                    onClick={() => setShowModal(true)} // Open modal on click
                  >
                    {loading ? "Deleting..." : "Delete Account"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Confirmation Modal */}
      <Modal
        size="lg"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        centered
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <Box className={Cx.popuarea}>
          <h4>Are you sure you want to delete your account?</h4>
          <div className={Cx.modalActions}>
            <button className={Cx.submitButton2} onClick={() => setShowModal(false)}>
              No
            </button>

            <button className={Cx.submitButton2} onClick={handleSubmit} disabled={loading}>
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteAccount;
